import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  disabled?: boolean
  hidden?: boolean
  label?: string
  selected?: boolean
  style?: any
  value: string
}

export const Option = memo(function Option({
  disabled,
  hidden,
  label,
  selected,
  value,
}: Props) {
  return (
    <Container
      value={value}
      disabled={disabled}
      hidden={hidden}
      selected={selected}
    >
      {label ? label : value}
    </Container>
  )
})

const Container = styled.option`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
`
