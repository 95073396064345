import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { At, CallOutline, Logo as Icon, Pin } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  email?: string
  languageCode: string
  mainPhone?: string
  mobilePhone?: string
  secondaryPhone?: string
  siteName?: string
}

export const ContactDetails = memo(function ContactDetails({
  address,
  email,
  languageCode,
  mainPhone,
  mobilePhone,
  secondaryPhone,
  siteName,
}: Props) {
  return (
    <Container>
      <FadeInUp>
        <Logo dial={5}>
          <Icon />
        </Logo>
      </FadeInUp>

      {siteName ? (
        <FadeInUp>
          <SiteName>{siteName}</SiteName>
        </FadeInUp>
      ) : null}

      <FadeInUp>
        <Wrapper dial={2} row wrap>
          {address ? (
            <Item>
              <Pin />

              <Label>{useVocabularyData('address', languageCode)}</Label>

              <Address dangerouslySetInnerHTML={{ __html: address }} />
            </Item>
          ) : null}

          <Item>
            <CallOutline />

            <Label>{useVocabularyData('phone', languageCode)}</Label>

            {mainPhone ? (
              <Anchor
                href={`tel:${mainPhone}`}
                onClick={() => {
                  if (isMobile) {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Phone Number',
                      })
                  }
                }}
              >
                {`${useVocabularyData('phone', languageCode)}: ${mainPhone}`}
              </Anchor>
            ) : null}

            {secondaryPhone ? (
              <>
                <Separator />

                <Anchor
                  href={`tel:${secondaryPhone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })
                    }
                  }}
                >
                  {`${useVocabularyData(
                    'phone',
                    languageCode,
                  )}: ${secondaryPhone}`}
                </Anchor>
              </>
            ) : null}

            {mobilePhone ? (
              <>
                <Separator />

                <Anchor
                  href={`tel:${mobilePhone}`}
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })
                    }
                  }}
                >
                  {`${useVocabularyData(
                    'mobile',
                    languageCode,
                  )}: ${mobilePhone}`}
                </Anchor>
              </>
            ) : null}
          </Item>

          {email ? (
            <Item>
              <At />

              <Label>{useVocabularyData('email', languageCode)}</Label>

              <Anchor
                href={`mailto:${email}`}
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                <Inner>{email}</Inner>
              </Anchor>
            </Item>
          ) : null}
        </Wrapper>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6.25rem 1.875rem 6.5625rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }
`

const Logo = styled(FlexBox)`
  width: 5rem;
  height: 5rem;
  background: ${({ theme }) => theme.colors.variants.primaryDark3};
  margin: 0 auto 1.25rem;

  svg {
    width: auto;
    height: 2.9375rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const SiteName = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.8125rem;
`

const Wrapper = styled(FlexBox)`
  max-width: 62.5rem;
  margin: 4.375rem auto 0;

  @media (max-width: 1199px) {
    max-width: none;
    margin-top: 3.75rem;
  }
`

const Item = styled.div`
  width: 33%;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;

  svg {
    width: auto;
    height: 1.875rem;
    fill: ${({ theme }) => theme.colors.variants.primaryDark3};
    margin-bottom: 1.25rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 2.5rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  margin-bottom: 0.625rem;
  text-transform: uppercase;
`

const Address = styled.div``

const Separator = styled.div``

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
`

const Inner = styled.span`
  text-decoration: underline;
`
